var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('validation-provider',{attrs:{"name":"Nombre gerente","rules":"required|max:80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"vid":"CEOName","type":"text","outlined":"","dense":"","error-messages":errors,"label":"Nombre","required":""},model:{value:(_vm.model.CEOName),callback:function ($$v) {_vm.$set(_vm.model, "CEOName", $$v)},expression:"model.CEOName"}})]}}])})],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('validation-provider',{attrs:{"name":"Correo electrónico gerente","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"vid":"CEOEmail","type":"text","outlined":"","dense":"","error-messages":errors,"label":"Correo electrónico","required":""},model:{value:(_vm.model.CEOEmail),callback:function ($$v) {_vm.$set(_vm.model, "CEOEmail", $$v)},expression:"model.CEOEmail"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('validation-provider',{attrs:{"name":"Cargo gerente","rules":"required|max:80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"vid":"CEOPositionDetail","type":"text","outlined":"","dense":"","error-messages":errors,"label":"Cargo","required":""},model:{value:(_vm.model.CEOPositionDetail),callback:function ($$v) {_vm.$set(_vm.model, "CEOPositionDetail", $$v)},expression:"model.CEOPositionDetail"}})]}}])})],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"d-flex flex-wrap"},[_c('div',{staticClass:"flex-grow-1"},[_c('validation-provider',{attrs:{"name":"Teléfono gerente","rules":{required: true,phone:true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-tel-input-vuetify',{attrs:{"label":"Teléfono","placeholder":"","error-messages":errors,"mode":"international","inputOptions":{showDialCode:false},"required":"","outlined":"","dense":"","preferred-countries":['CO', 'US']},model:{value:(_vm.model.CEOPhone),callback:function ($$v) {_vm.$set(_vm.model, "CEOPhone", $$v)},expression:"model.CEOPhone"}})]}}])})],1),_c('div',{staticClass:"flex-grow-1"},[_c('validation-provider',{attrs:{"name":"Extensión","rules":"integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"vid":"CEOPhoneExtension","type":"text","outlined":"","dense":"","error-messages":errors,"label":"Extensión","required":""},model:{value:(_vm.model.CEOPhoneExtension),callback:function ($$v) {_vm.$set(_vm.model, "CEOPhoneExtension", $$v)},expression:"model.CEOPhoneExtension"}})]}}])})],1)])])]),_vm._m(1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_vm._m(2),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('validation-provider',{attrs:{"name":"Nombre primer contacto","rules":"required|max:80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"vid":"firstContactName","type":"text","outlined":"","dense":"","error-messages":errors,"label":"Nombre","required":""},model:{value:(_vm.model.firstContactName),callback:function ($$v) {_vm.$set(_vm.model, "firstContactName", $$v)},expression:"model.firstContactName"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":"Correo electrónico primer contacto","rules":"required|email|firstContactEmail:@secondContactEmail|forbiddenUsernames","vid":"firstContactEmail"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"type":"email","outlined":"","dense":"","error-messages":errors,"label":"Correo electrónico","required":""},model:{value:(_vm.model.firstContactEmail),callback:function ($$v) {_vm.$set(_vm.model, "firstContactEmail", $$v)},expression:"model.firstContactEmail"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}])},[_c('span',[_vm._v("Correos no permitidos: abuse, admin, avidandlb, billing, compliance, devnull, ftp, hostmaster, inoc, ispfeedback, ispsupport, list, list-request, maildaemon, noc, noreplyno-reply, null, phish, phishing, popmaster, postmaster, privacy, registrar, root, security, soporte, spam, support, sysadmin, tech, undisclosed-recipients, unsubscribe, usenet, uucp, webmaster, webmasters, www")])])],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('validation-provider',{attrs:{"name":"Cargo primer contacto","rules":"required|max:80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"vid":"firstContactOccupationDetail","type":"text","outlined":"","dense":"","error-messages":errors,"label":"Cargo","required":""},model:{value:(_vm.model.firstContactPositionDetail),callback:function ($$v) {_vm.$set(_vm.model, "firstContactPositionDetail", $$v)},expression:"model.firstContactPositionDetail"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"d-flex flex-wrap"},[_c('div',{staticClass:"flex-grow-1"},[_c('validation-provider',{attrs:{"name":"Teléfono primer contacto","rules":{phone:true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-tel-input-vuetify',{attrs:{"label":"Teléfono","mode":"international","error-messages":errors,"required":"","outlined":"","dense":"","preferred-countries":['CO', 'US']},model:{value:(_vm.model.firstContactPhone),callback:function ($$v) {_vm.$set(_vm.model, "firstContactPhone", $$v)},expression:"model.firstContactPhone"}})]}}])})],1),_c('div',{staticClass:"flex-grow-1"},[_c('validation-provider',{attrs:{"name":"Extensión","rules":"integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"vid":"CEOPhoneExtension","type":"text","outlined":"","dense":"","error-messages":errors,"label":"Extensión","required":""},model:{value:(_vm.model.firstContactPhoneExtension),callback:function ($$v) {_vm.$set(_vm.model, "firstContactPhoneExtension", $$v)},expression:"model.firstContactPhoneExtension"}})]}}])})],1)])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('validation-provider',{attrs:{"name":"Celular primer contacto","rules":{required:true,phone:true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-tel-input-vuetify',{attrs:{"label":"Celular","error-messages":errors,"mode":"international","inputOptions":{showDialCode:false},"required":"","outlined":"","dense":"","preferred-countries":['CO', 'US']},model:{value:(_vm.model.firstContactMobilePhone),callback:function ($$v) {_vm.$set(_vm.model, "firstContactMobilePhone", $$v)},expression:"model.firstContactMobilePhone"}})]}}])})],1)])]),_c('div',{staticClass:"col-12 col-md-6"},[_vm._m(3),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('validation-provider',{attrs:{"name":"Nombre segundo contacto","rules":"required|max:80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"vid":"secondContactName","type":"text","outlined":"","dense":"","error-messages":errors,"label":"Nombre","required":""},model:{value:(_vm.model.secondContactName),callback:function ($$v) {_vm.$set(_vm.model, "secondContactName", $$v)},expression:"model.secondContactName"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":"Correo electrónico segundo contacto","rules":"required|email|secondContactEmail:@firstContactEmail|forbiddenUsernames","vid":"secondContactEmail"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"type":"email","outlined":"","dense":"","error-messages":errors,"label":"Correo electrónico","required":""},model:{value:(_vm.model.secondContactEmail),callback:function ($$v) {_vm.$set(_vm.model, "secondContactEmail", $$v)},expression:"model.secondContactEmail"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}])},[_c('span',[_vm._v("Correos no permitidos: abuse, admin, avidandlb, billing, compliance, devnull, ftp, hostmaster, inoc, ispfeedback, ispsupport, list, list-request, maildaemon, noc, noreplyno-reply, null, phish, phishing, popmaster, postmaster, privacy, registrar, root, security, soporte, spam, support, sysadmin, tech, undisclosed-recipients, unsubscribe, usenet, uucp, webmaster, webmasters, www")])])],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('validation-provider',{attrs:{"name":"Cargo segundo contacto","rules":"required|max:80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"vid":"secondContactOccupationDetail","type":"text","outlined":"","dense":"","error-messages":errors,"label":"Cargo","required":""},model:{value:(_vm.model.secondContactPositionDetail),callback:function ($$v) {_vm.$set(_vm.model, "secondContactPositionDetail", $$v)},expression:"model.secondContactPositionDetail"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"d-flex flex-wrap"},[_c('div',{staticClass:"flex-grow-1"},[_c('validation-provider',{attrs:{"name":"Teléfono segundo contacto","rules":{phone:true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-tel-input-vuetify',{attrs:{"label":"Teléfono","error-messages":errors,"mode":"international","required":"","outlined":"","dense":"","preferred-countries":['CO', 'US']},model:{value:(_vm.model.secondContactPhone),callback:function ($$v) {_vm.$set(_vm.model, "secondContactPhone", $$v)},expression:"model.secondContactPhone"}})]}}])})],1),_c('div',{staticClass:"flex-grow-1"},[_c('validation-provider',{attrs:{"name":"Extensión","rules":"integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"vid":"CEOPhoneExtension","type":"text","outlined":"","dense":"","error-messages":errors,"label":"Extensión","required":""},model:{value:(_vm.model.secondContactPhoneExtension),callback:function ($$v) {_vm.$set(_vm.model, "secondContactPhoneExtension", $$v)},expression:"model.secondContactPhoneExtension"}})]}}])})],1)])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('validation-provider',{attrs:{"name":"Celular segundo contacto","rules":{required:true,phone:true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-tel-input-vuetify',{attrs:{"label":"Celular","error-messages":errors,"mode":"international","inputOptions":{showDialCode:false},"required":"","outlined":"","dense":"","preferred-countries":['CO', 'US']},model:{value:(_vm.model.secondContactMobilePhone),callback:function ($$v) {_vm.$set(_vm.model, "secondContactMobilePhone", $$v)},expression:"model.secondContactMobilePhone"}})]}}])})],1)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h3',[_vm._v("Gerente de la empresa")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-h5 red--text"},[_c('span',{staticClass:"font-bold"},[_vm._v("Nota:")]),_vm._v(" Los "),_c('span',{staticClass:"font-italic"},[_vm._v("Contactos Feria Renovables")]),_vm._v(" , son las personas que recibirán todas las comunicaciones para la participación en la Feria. Por lo anterior, es importante que tengan la disponibilidad para leer y analizar la información enviada y en caso de ser necesario, responder oportunamente. "),_c('br'),_vm._v("El primer y segundo contacto no pueden tener el mismo correo y deben ser únicos por reserva. (No deben haber sido usados en otra reserva de esta feria) ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h3',[_vm._v("Primer contacto para Feria Renovables")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h3',[_vm._v("Segundo contacto para Feria Renovables")])])])}]

export { render, staticRenderFns }